import React, { useEffect, useState } from 'react';

interface DownloadButtonProps {
    filePrefix: string;
    fileExtension: string;
    tooltip: string;
    dataType: string;
    getData: Function;
}

const DownloadButton = (props: DownloadButtonProps) => {

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    useEffect(() => {
        // to fix vertical alignement of se-button with icon
        const allDownloadButtons = document.querySelectorAll(".download-button")
        if (allDownloadButtons) {
            allDownloadButtons.forEach(function (downloadButton) {
                if (downloadButton.shadowRoot) {
                    downloadButton.shadowRoot.append(
                        Object.assign(document.createElement("style"), {
                            innerText: `button.hasIcon{ 
                                        position: inherit !important;
                                    }`
                        })
                    )
                }
            })
        }
    }, [])

    const downloadFile = async () => {
        try {
            setIsDownloading(true);
            const data = await props.getData()
            if (data) {
                const url = window.URL.createObjectURL(new Blob([data], { type: props.dataType }));
                const a = document.createElement('a');
                a.href = url;
                a.download = getFileName();
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // This will remove the link from DOM after completion of the process.
            }
        } catch (error) {
            setIsDownloading(false);
        } finally {
            setIsDownloading(false);
        }
    }

    const getFileName = () => {
        return props.filePrefix + '-' + new Date().toISOString() + props.fileExtension;
    }

    return (
        <se-button selected={isDownloading}
            class='download-button'
            icon="action_download_stroke"
            size='small'
            option="flat"
            color="standard"
            title={props.tooltip}
            onClick={() => { downloadFile() }} />
    )
}

export default DownloadButton