import axios from "axios";
import { webServiceUrl } from "../configuration"

export default class AuditApi {
    
    getDeviceAudit = async (accessToken: string, deviceId: string) => {
        try {
            return await axios.get(`${webServiceUrl}/audit/${deviceId}`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
        } catch(error: any) {
            return error.response
        }
}}